export function ajax(o, limitTime) {
  if (o.lock) {
    if ($(o.lock).data('lock')) {
      return;
    }

    $(o.lock).data('lock', 1);
  }

  return $.ajax({
    type: o.type ? o.type : 'GET',
    url: o.url,
    data: o.data,
    async: o.async == false ? o.async : true,
    timeout: limitTime || 120000,
    success: function (res) {
      // var res = typeof res == 'object' ? res : $.parseJSON(res);

      if (res.code) {
        if (res.code == 302) {
          var href = window.location.href;

          window.location.href=href.indexOf('org.cn')>-1 ? location.protocol+'//sso.scimall.org.cn':location.protocol+'//sso.scimall.vip';
        } else {

          if (res.error) {
            layer.msg(res.error[0].value);
            return;
          }

          layer.msg(res.msg);
        }
        // return false;
      }
      o.callback && o.callback(res);

      if (o.lock) {
        $(o.lock).data('lock', 0);
      }
    },
    error: function (errMsg) {
      let tipsTxt = '网络异常，请稍后再试!';

      if (errMsg.statusText == 'timeout') {
        tipsTxt = '请求超时';
      }
      o.errorCallback && o.errorCallback();
      layer.msg(tipsTxt);
      $(o.lock).data('lock', 0);
    }
  });
}

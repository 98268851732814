import { strToObj, jsonToStr } from './string.js';

const isLocalStorageSupported = (function () {
  return () => {
    let testKey = 'test',
      storage = window.localStorage;

    try {
      storage.setItem(testKey, 'testValue');
      storage.removeItem(testKey);
    } catch (errorMsg) {
      alert("若您无法正常浏览数据，建议您关闭隐私模式/无痕模式。");

      return false;
    }
  };
}());

if (!window.localStorage) {
  window.localStorage = {
    getItem(sKey) {
      if (!sKey || !Object.prototype.hasOwnProperty.call(this, sKey)) {
        return null;
      }

      return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
    },
    key(nKeyId) {
      return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
    },
    setItem(sKey, sValue) {
      if (!sKey) {
        return;
      }

      document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
      this.length = document.cookie.match(/\=/g).length;
    },
    length: 0,
    removeItem(sKey) {
      if (!sKey || !Object.prototype.hasOwnProperty.call(this, sKey)) {
        return;
      }

      document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      this.length--;
    },
    hasOwnProperty: function (sKey) {
      return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }
  };

  window.localStorage.length = (document.cookie.match(/\=/g) || window.localStorage).length;
}

export let dataStorage = {
  localStorage: {},
  sessionStorage: {}
};

dataStorage.localStorage.setItem = (key, value) => {
  isLocalStorageSupported();
  window.localStorage.setItem(key, jsonToStr(value));
};

dataStorage.localStorage.getItem = (key) => {
  isLocalStorageSupported();
  return strToObj(window.localStorage.getItem(key));
};

dataStorage.localStorage.clear = () => {
  isLocalStorageSupported();
  window.localStorage.clear();
};

dataStorage.sessionStorage.setItem = (key, value) => {
  isLocalStorageSupported();
  window.sessionStorage.setItem(key, jsonToStr(value));
};

dataStorage.sessionStorage.getItem = (key) => {
  isLocalStorageSupported();
  return strToObj(window.sessionStorage.getItem(key));
};

dataStorage.sessionStorage.clear = () => {
  isLocalStorageSupported();
  window.localStorage.clear();
};
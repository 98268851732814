if (!String.prototype.repeat) {
  String.prototype.repeat = function (count) {
    if (this == null) {
      throw new TypeError('can\'t convert ' + this + ' to object');
    }
    var str = '' + this;

    count = +count;
    if (isNaN(count)) {
      count = 0;
    }
    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }
    if (count == Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }
    count = Math.floor(count);
    if (str.length == 0 || count == 0) {
      return '';
    }
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }
    var maxCount = str.length * count;

    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}

/**
 * 替换子字符串
 * @method str2char
 * @param {string} str - 原始字符串
 * @param {int} [start=3] - 替换的开始位置
 * @param {int} [length=4] - 要替换的长度
 * @return {String}
 * @public
 */
export function str2char(str, start = 3, length = 4, char = '*') {
  let result, maxStrLen;

  if (typeof str != 'string') {
    return '';
  }

  maxStrLen = Math.min(str.length, start + length);

  result = str.substr(0, start)
        + char.repeat(length)
        + (maxStrLen > str.length ? '' : str.substr(start + length, str.length));

  return result;
}

/**
 * 字符串转对象
 * @method strToObj
 * @param value 所传参数为JSON字符串
 * @return {Object}
 * @public
 */
export function strToObj(value) {
  let result,
    prefix = '';

  if (value === null || value === undefined) {
    return null;
  }

  let realVal = value.substr(prefix.length, prefix.length + 1);

  if (realVal !== '' && '[{'.indexOf(realVal) > -1) {
    return JSON.parse(value.substr(prefix.length, value.length));
  } else {
    return value.substr(prefix.length, value.length);
  }
}

/**
 * JSON对象转字符串
 * @method jsonToStr
 * @param value 所传参数为JSON对象
 * @return {String}
 * @public
 */
export function jsonToStr(value) {
  if (value === null || value === undefined) {
    return null;
  }

  let prefix = '';

  return typeof value === 'string' ? value : prefix + JSON.stringify(value);
}

/**
 * 获取、查询字符串
 * @method queryString
 * @param name 可选,不传,返回一个包含所有参数的对象
 * @param url 可选，要查询的url
 * @return {Object}
 * @public
 */
export function queryString(name, url = window.location.href) {
  if (name !== undefined) {
    name = name.replace(/[\[\]]/g, "\\$&");

    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    try {
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    } catch (error) {
      var query = new URLSearchParams(location.search);
      return query.get(name);
    }
  }

  let pairs = location.search.slice(1).split('&'),
    result = {};

  if (pairs.length === 0) {
    return {};
  }

  for (let i=pairs.length - 1, pair; i > -1; i--) {
    pair = pairs[i].split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  }

  return JSON.parse(JSON.stringify(result));
}

// todo 获取中英文字符串长度


// todo 自定义截取字符串长度


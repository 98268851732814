import { strToObj, jsonToStr, str2char, queryString } from './utilities/string.js';
import { browser } from './utilities/browser.js';
import { cookies } from './utilities/cookies.js';
import { dataStorage } from './utilities/dataStorage.js';
import { dateFormat, nowTimeStamp } from './utilities/date.js';
import { numRound, isInteger, zeroPadding, getNumberByTenThousand } from './utilities/number.js';
import { ajax } from './utilities/ajax.js';
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

window.util = {
  strToObj: strToObj,
  jsonToStr: jsonToStr,
  str2char: str2char,
  getQueryString: queryString,
  browser: browser,
  cookies: cookies,
  dataStorage: dataStorage,
  format: dateFormat,
  nowTimeStamp: nowTimeStamp,
  numRound: numRound,
  getNumberByTenThousand: getNumberByTenThousand,
  isInteger: isInteger,
  zeroPadding: zeroPadding,
  ajax: ajax
};